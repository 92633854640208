import React,{Component,Fragment} from "react";
import {Switch,Route,Redirect} from "react-router-dom";
import NotFound from "../pages/NotFound"
import Login from "../pages/Login";
import Coins from "../pages/Coins";
import AddCurrency from "../pages/AddCurrency";
import EditCurrency from "../pages/EditCurrency";
import Blog from "../pages/Blog";
import AddBlog from "../pages/AddBlog";
import EditBlog from "../pages/EditBlog";
import Users from "../pages/Users";
import UserUpdate from "../pages/UserUpdate";
import ReferralSettings from "../pages/ReferralSettings";
import AllPayments from "../pages/AllPayments";
import AllDeposit from "../pages/AllDeposit";
import AllWithdrew from "../pages/Withdrew";
import WithdrewEdit from "../pages/WithdrewEdit";
import UserDetails from "../pages/UserDetails";
import IcoMarget from "../pages/IcoMarget";
import AddICO from "../pages/AddICO";
import EditICO from "../pages/EditICO";
import WithdrewPending from "../pages/WithdrewPending";
import PresaleHistory from "../pages/PresaleHistory";

class RouterPage extends Component {
    render() { 
        return ( 
         <Fragment>   
            <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/currency" component={Coins}/>
                <Route exact path="/currency/add" component={AddCurrency}/>
                <Route exact path="/currency/edit/:id" component={EditCurrency}/>
                <Route exact path="/ico" component={IcoMarget}/>
                <Route exact path="/ico/add" component={AddICO}/>
                <Route exact path="/ico/edit/:id" component={EditICO}/>
                <Route exact path="/blog" component={Blog}/>
                <Route exact path="/blog/add" component={AddBlog}/>
                <Route exact path="/blog/edit/:id" component={EditBlog}/>
                <Route exact path="/" component={Users}/>
                <Route exact path="/user/edit/:id" component={UserUpdate}/>
                <Route exact path="/user/details/:id" component={UserDetails}/>
                <Route exact path="/referral/settings" component={ReferralSettings}/>
                <Route exact path="/payments" component={AllPayments}/>
                <Route exact path="/deposit" component={AllDeposit}/>
                <Route exact path="/withdrew" component={AllWithdrew}/>
                <Route exact path="/withdrew/pending" component={WithdrewPending}/>
                <Route exact path="/withdrew/edit/:id" component={WithdrewEdit}/>
                <Route exact path="/presale" component={PresaleHistory}/>

                <Route component={NotFound}/>
          </Switch>
        </Fragment>
         );
    }
}
 
export default RouterPage;