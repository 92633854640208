import React, {Component} from 'react';
import Menu from "../components/Menu";
import Axios from "axios";
import AppUrl from "../AppUrl/ApiUrl";
import {Link} from "react-router-dom";
import Loading from "../components/Loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {toast} from "react-toastify";
import moment from "moment";

class PresaleHistory extends Component {
    constructor() {
        super();
        this.state={
            token:'',uid:"",count:20,fromDate:new Date(),toDate:new Date(),
            start:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            end:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            data:[],coin:[],coin_raw_id:"",loading:true,status:"",type:"",pcount:""
        }
    }
    async componentDidMount() {
        await Axios.get(AppUrl.baseurl+"all-coin")
            .then(res=>{
                console.log(res.data)
                this.setState({coin:res.data})
            })
            .catch(err=>{
                console.log(err)
            })
        await this.myPayments()
    }

    status=(e)=>{
        this.setState({status:e.target.value})
    }
    type=(e)=>{
        this.setState({type:e.target.value})
    }
    coin_raw_id=(e)=>{
        this.setState({coin_raw_id:e.target.value})
    }

    myPayments=()=>{
        var token = localStorage.getItem("admintoken")
        if(token){
            Axios.get(AppUrl.baseurl+"ico-trx")
                .then(res => {
                    if (res.data.length>0) {
                        this.setState({data: res.data, loading: false})
                    }
                    this.setState({loading: false})
                })
                .catch(err => {
                    console.log(err)
                    this.myPayments()
                })
        }
    }

    loadMore=async (e)=>{
        var countval = this.state.count+20
        await this.setState({count:countval})
        await this.myPayments()
    }


    render() {
        const datapending = this.state.data.map(res=>{
            var tl = (parseFloat(res.amount)*parseFloat(res.price))
            return(
                <>
                    <tr>
                        <td>{moment(res.created_at).format("MMM Do YY")}</td>
                        <td><Link to={"/user/details/"+res.uid}>{res.uid}</Link> </td>
                        <td>{res.id} </td>
                        <td>{res.amount} { res.base_symbol} </td>
                        <td>{res.price} { res.target_symbol} </td>
                        <td>{tl.toFixed(tl<1?6:2)} { res.target_symbol}  </td>
                    </tr>
                </>
            )
        },)


        return (

            <Menu title="All Presale history">
                <>
                    <h3 className="bg-white title-head"> All Presale History ({this.state.data.length})</h3>
                    <div className="row m-0 p-1 bg-white">

                    </div>

                    <div className="bg-white m-0 pt-2">
                        <div className="table-responsive report-table-main ">
                            <title>All Withdrew history </title>
                            <table className="table table-striped report-table ">
                                <thead>
                                <tr>
                                    <th>Time (UTC)</th>
                                    <th>UID </th>
                                    <th>ID  </th>
                                    <th>Amount  </th>
                                    <th>Price </th>
                                    <th>Total </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.loading==true?<Loading/>:datapending}
                                </tbody>
                            </table>
                            {
                                this.state.loading==true?"":
                                    this.state.data.length>0?""
                                        :<p className="noorderdata">Presale history Data Not Available</p>
                            }

                        </div>
                    </div>

                    </>
            </Menu>
        );
    }
}

export default PresaleHistory;